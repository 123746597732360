import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isIFSPlanActiveSelector } from '../../../redux/selectors/wasm_viewer/wasm-viewer';
import TeethUtils, { ALPHANUMERIC_FDI_MAPPING } from '../../../common/teeth_utils';
import { setActiveTeeth, updateMovementTable } from '../../../redux/actions/wasm_viewer/wasm_viewer';
import { getActiveToothIds, getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import { setSelectedTooth, updateFinalMovementTableValues } from '../wasm_controller/wasm_controller';
import { HideContentIf } from '../../../common/hocs/hide-content-if';
import { isValueNegative, tmtInputOnChangeValidator } from './pipes/validator.pipe';
import { takeAbsValue, tmtInputOnBlurTransformer } from './pipes/transformer.pipe';
import { ETmtKey, ETmtDecrKey, ETmtIncrKey, EDeltaType, EMovementSigns, movementTableFractionDigits } from './constants';
import { getCaseStages, isEnrolledProviderEditPhase2 } from '../../../redux/reducers/common/common_case_details';

export const tmtKeyToDeltaType = {
  [ETmtKey.Angulation]: EDeltaType.Angular,
  [ETmtKey.Inclination]: EDeltaType.Angular,
  [ETmtKey.Rotation]: EDeltaType.Angular,
  [ETmtKey.Mesial]: EDeltaType.Spacial,
  [ETmtKey.Vestibular]: EDeltaType.Spacial,
  [ETmtKey.Occlusal]: EDeltaType.Spacial,
  [ETmtKey.MesialHinge]: EDeltaType.Angular,
  [ETmtKey.DistalHinge]: EDeltaType.Angular,
  [ETmtKey.RootTorque]: EDeltaType.Angular,
  [ETmtKey.CrownTorque]: EDeltaType.Angular,
};
export const tmtKeyToDecr = {
  [ETmtKey.Angulation]: ETmtDecrKey.Angulation,
  [ETmtKey.Inclination]: ETmtDecrKey.Inclination,
  [ETmtKey.Rotation]: ETmtDecrKey.Rotation,
  [ETmtKey.Mesial]: ETmtDecrKey.Mesial,
  [ETmtKey.Vestibular]: ETmtDecrKey.Vestibular,
  [ETmtKey.Occlusal]: ETmtDecrKey.Occlusal,
  [ETmtKey.Rotation]: ETmtDecrKey.Rotation,
  [ETmtKey.Mesial]: ETmtDecrKey.Mesial,
  [ETmtKey.Vestibular]: ETmtDecrKey.Vestibular,
  [ETmtKey.Occlusal]: ETmtDecrKey.Occlusal,
  [ETmtKey.RootTorque]: ETmtDecrKey.RootTorque,
  [ETmtKey.CrownTorque]: ETmtDecrKey.CrownTorque,
};
export const tmtKeyToIncr = {
  [ETmtKey.Angulation]: ETmtIncrKey.Angulation,
  [ETmtKey.Inclination]: ETmtIncrKey.Inclination,
  [ETmtKey.Rotation]: ETmtIncrKey.Rotation,
  [ETmtKey.Mesial]: ETmtIncrKey.Mesial,
  [ETmtKey.Vestibular]: ETmtIncrKey.Vestibular,
  [ETmtKey.Occlusal]: ETmtIncrKey.Occlusal,
  [ETmtKey.Rotation]: ETmtIncrKey.Rotation,
  [ETmtKey.Mesial]: ETmtIncrKey.Mesial,
  [ETmtKey.Vestibular]: ETmtIncrKey.Vestibular,
  [ETmtKey.Occlusal]: ETmtIncrKey.Occlusal,
  [ETmtKey.RootTorque]: ETmtIncrKey.RootTorque,
  [ETmtKey.CrownTorque]: ETmtIncrKey.CrownTorque,
};

export const FIELDS = [
  {
    text: 'Intrusion/Extrusion (mm)',
    key: ETmtKey.Occlusal,
    signs: EMovementSigns[ETmtKey.Occlusal],
  },
  {
    text: 'Lingual/Buccal (mm)',
    key: ETmtKey.Vestibular,
    signs: EMovementSigns[ETmtKey.Vestibular],
  },
  {
    text: 'Distal/Mesial (mm)',
    key: ETmtKey.Mesial,
    signs: EMovementSigns[ETmtKey.Mesial],
  },
  {
    text: 'Rotation (°)',
    key: ETmtKey.Rotation,
    signs: EMovementSigns[ETmtKey.Rotation],
  },
  {
    text: 'Angulation (°)',
    key: ETmtKey.Angulation,
    signs: EMovementSigns[ETmtKey.Angulation],
  },
  {
    text: 'Inclination (°)',
    key: ETmtKey.Inclination,
    signs: EMovementSigns[ETmtKey.Inclination],
  },
];

const INCR_FIELDS = [
  {
    text: 'Angulation Decrement',
    key: tmtKeyToDecr[ETmtKey.Angulation],
  },
  {
    text: 'Angulation Increment',
    key: tmtKeyToIncr[ETmtKey.Angulation],
  },
  {
    text: 'Inclination Decrement',
    key: tmtKeyToDecr[ETmtKey.Inclination],
  },
  {
    text: 'Inclination Increment',
    key: tmtKeyToIncr[ETmtKey.Inclination],
  },
  {
    text: 'Mesial Decrement',
    key: tmtKeyToDecr[ETmtKey.Mesial],
  },
  {
    text: 'Mesial Increment',
    key: tmtKeyToIncr[ETmtKey.Mesial],
  },
  {
    text: 'Occlusal Decrement',
    key: tmtKeyToDecr[ETmtKey.Occlusal],
  },
  {
    text: 'Occlusal Increment',
    key: tmtKeyToIncr[ETmtKey.Occlusal],
  },
  {
    text: 'Rotation Decrement',
    key: tmtKeyToDecr[ETmtKey.Rotation],
  },
  {
    text: 'Rotation Increment',
    key: tmtKeyToIncr[ETmtKey.Rotation],
  },
  {
    text: 'Vestibular Decrement',
    key: tmtKeyToDecr[ETmtKey.Vestibular],
  },
  {
    text: 'Vestibular Increment',
    key: tmtKeyToIncr[ETmtKey.Vestibular],
  },
];
/**
 * Generates initial data for teeth movement.
 *
 * @param {string} teeth - The identifier for the teeth.
 * @returns {Object} - The initial data for teeth movement.
 */
function getTeethInitialData(teeth) {
  const fdi = ALPHANUMERIC_FDI_MAPPING[teeth];
  return {
    FDI: fdi,
    [ETmtKey.Angulation]: '',
    [ETmtKey.Inclination]: '',
    [ETmtKey.Mesial]: '',
    [ETmtKey.Occlusal]: '',
    [ETmtKey.Rotation]: '',
    [ETmtKey.Vestibular]: '',
    Selected: false,
    SelectedActive: false,
    [ETmtDecrKey.Angulation]: false,
    [ETmtIncrKey.Angulation]: false,
    [ETmtDecrKey.Inclination]: false,
    [ETmtIncrKey.Inclination]: false,
    [ETmtDecrKey.Mesial]: false,
    [ETmtIncrKey.Mesial]: false,
    [ETmtDecrKey.Occlusal]: false,
    [ETmtIncrKey.Occlusal]: false,
    [ETmtDecrKey.Rotation]: false,
    [ETmtIncrKey.Rotation]: false,
    [ETmtDecrKey.Vestibular]: false,
    [ETmtIncrKey.Vestibular]: false,
    [ETmtDecrKey.DistalHinge]: false,
    [ETmtIncrKey.DistalHinge]: false,
    [ETmtDecrKey.MesialHinge]: false,
    [ETmtIncrKey.MesialHinge]: false,
    [ETmtDecrKey.RootTorque]: false,
    [ETmtIncrKey.RootTorque]: false,
    [ETmtDecrKey.CrownTorque]: false,
    [ETmtIncrKey.CrownTorque]: false,
  };
}
/**
 * Rounds the values in the tooth movement data to two decimal places.
 *
 * @param {Object} data - The tooth movement data.
 * @returns {Object} - The rounded tooth movement data.
 */
function roundToothMovementData(data) {
  const res = { ...data };
  FIELDS.forEach((field) => {
    const dataNum = Number(data[field.key]) || 0;
    const value = dataNum.toFixed(movementTableFractionDigits);
    res[field.key] = parseFloat(value) || '';
  });
  INCR_FIELDS.forEach((f) => {
    res[f.key] = data[f.key];
  });
  return res;
}

/**
 * Gets the identifier of the active teeth from a Tooth Movement Table (TMT).
 *
 * @param {Array} tmt - The Tooth Movement Table data.
 * @returns {string} - The identifier of the active teeth.
 */
export function getActiveTeeth(tmt) {
  if (tmt) {
    const active_teeth = tmt.find((t) => t.Selected);
    return active_teeth?.AlphanumericID ?? '';
  }
  return '';
}
/**
 * Formats the provided movement data for display.
 *
 * @param {Array} move_data - The raw movement data.
 * @returns {Object} - The formatted movement data.
 */
export function formatMovementData(move_data) {
  if (move_data) {
    const teeth_set = TeethUtils.teethSetInPalmerBySection();
    const all_teeth = [...teeth_set.upper, ...teeth_set.lower];

    const result = {};
    all_teeth.forEach((teeth) => (result[teeth] = getTeethInitialData(teeth)));
    move_data.forEach((data) => (result[data['AlphanumericID']] = roundToothMovementData(data)));

    return result;
  }
}

/**
 * Get value of movement
 * If the user is seeing the Initial Movement (step = 0), the value should be empty.
 * @param {number | string} value The value of the movement.
 * @param {boolean} isHidden Flag indicating if the user is enrolled in Phase 2.
 * @param {boolean} isEnrolledPhase2 Flag indicating if the user is enrolled in Phase 2.
 * @returns {number | string}
 */
function getValue(value, isHidden, isEnrolledPhase2 = false) {
  if (!value || isHidden) {
    return '';
  }

  return isEnrolledPhase2 ? takeAbsValue(value) : value;
}

/**
 * Get sign of value
 * @param {number | string} value The value of the movement.
 * @param {{
 *   neg: string,
 *   pos: string,
 * }} signs The value of the movement.
 * @param {boolean} isHidden Flag indicating if the user is enrolled in Phase 2.
 * @returns {string}
 */
function getSign(value, signs, isHidden) {
  if (!value || isHidden) {
    return '';
  }

  return isValueNegative(value) ? signs.neg : signs.pos;
}
/**
 * Generates class names for a Tooth Movement Table (TMT) item based on specified conditions.
 *
 * @param {boolean} isEven - Flag indicating if the item is at an even position.
 * @param {boolean} [isMissing=false] - Flag indicating if the item represents a missing tooth.
 * @param {boolean} [isActive=false] - Flag indicating if the item is currently active.
 * @param {string} [value=''] - The value of the TMT item.
 * @returns {string} - The generated class names for the TMT item.
 */
export function getTmtClassNames(isEven, isMissing = false, isActive = false, value = '') {
  const isSingleDigit = value.length === 1;
  const isDoubleDigit = value.length === 2;
  const hasMoreThanTwoDigits = value.length > 2;
  return classNames({
    'wasm-tmt-item': true,
    'wasm-tmt-item--odd': !isEven,
    'wasm-tmt-item--disabled': isMissing,
    'wasm-tmt-item--active': isActive,
    'wasm-tmt-item--single-digit': isSingleDigit,
    'wasm-tmt-item--double-digit': isDoubleDigit,
    'wasm-tmt-item--more-than-two-digits': hasMoreThanTwoDigits,
  });
}

function WasmMovementTable(props) {
  const { missing_teeth, movement_table, active_teeth, setActiveTeeth, viewOnly, step, selectedToothIds, isEnrolledPhase2, caseStages } = props;
  const [arch, setArch] = useState('upper');
  const teeth_set = TeethUtils.teethSetInPalmerBySection();
  const tmt = arch === 'upper' ? teeth_set.upper : teeth_set.lower;
  const [movementData, setMovementData] = useState(movement_table);
  const isMultiSelection = selectedToothIds.length > 1;
  const isToothActive = (tooth) => !isMultiSelection && active_teeth === tooth;

  useEffect(() => {
    setMovementData(movement_table);
  }, [setMovementData, movement_table]);

  useEffect(() => {
    if (active_teeth && typeof active_teeth === 'string') {
      const newArch = active_teeth.startsWith('L') ? 'lower' : 'upper';
      setArch(newArch);
    }
  }, [active_teeth]);
  /**
   * Handles the change event for a specific input field.
   *
   * @param {Event} e - The change event object.
   * @param {String} tooth - The identifier for the set of teeth.
   * @param {String} field - The specific field being modified.
   */
  const onChange = (e, tooth, field) => {
    const value = e.target.value;
    const isValid = tmtInputOnChangeValidator.validate(value);
    if (!isValid) {
      return;
    }
    const isNegative = isValueNegative(movementData[tooth][field]);
    const newTeethData = {
      ...movementData[tooth],
      [field]: isNegative ? `-${value}` : value,
    };
    setMovementData({
      ...movementData,
      [tooth]: newTeethData,
    });
  };
  /**
   * Handles the focus event for a teeth input field.
   *
   * @param {String} teeth - The identifier for the teeth input field.
   * @returns {void}
   */
  const onFocus = (teeth) => {
    setActiveTeeth(teeth);
    setSelectedTooth(ALPHANUMERIC_FDI_MAPPING[teeth]);
  };
  /**
   * Formats the input value on blur, removing unnecessary trailing characters.
   *
   * @param {string} value - The input value to be formatted on blur.
   * @returns {string} - The formatted value.
   */
  function formatValueOnBlur(value) {
    if (value.endsWith('.')) {
      return value.slice(0, -1);
    } else if (value.endsWith('.0')) {
      return value.slice(0, -2);
    }
    return value;
  }
  /**
   * Handles the blur event for an input field associated with teeth movement data.
   *
   * @param {Event} e - The blur event.
   * @param {String} tooth - The identifier for the teeth associated with the input field.
   * @param {String} field - The field being edited.
   * @returns {void}
   */
  const onBlur = (e, tooth, field) => {
    let newTeethData;
    if (isEnrolledPhase2) {
      const value = tmtInputOnBlurTransformer.transform(e.target.value);
      if (value === '' || isNaN(value)) {
        return;
      }
      const isNegative = isValueNegative(movementData[tooth][field]);
      newTeethData = {
        ...movementData[tooth],
        [field]: parseFloat(isNegative ? `-${value}` : value),
      };
    } else {
      const value = formatValueOnBlur(e.target.value);
      if (value !== '') {
        newTeethData = {
          ...movementData[tooth],
          [field]: value,
        };
      }
    }
    setMovementData({
      ...movementData,
      [tooth]: newTeethData,
    });
    updateFinalMovementTableValues(newTeethData);
  };
  /**
   * Handles the key down event for an input field associated with teeth movement data.
   * This is used to allow the user to press the Enter to unselect all teeth.
   * !This is a temporary solution until there's a new WASM action to allow unselecting all teeth.
   * @param {Event} e - The key down event.
   * @returns {void}
   * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/KeyboardEvent
   */
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  if (!movementData) {
    return <div className="wasm-tmt-container">Teeth movement table not available</div>;
  }

  return (
    <div className="wasm-tmt-container">
      <div className="wasm-tmt-arch-option">
        <label>
          <input type="radio" value="upper" checked={arch === 'upper'} onChange={(e) => setArch(e.target.value)} /> Upper
        </label>
        <label>
          <input type="radio" value="lower" checked={arch === 'lower'} onChange={(e) => setArch(e.target.value)} /> Lower
        </label>
      </div>
      {tmt.map((tooth) => (
        <div key={tooth} className={classNames('wasm-tmt-header', { 'wasm-tmt-header--active': isToothActive(tooth) })}>
          {tooth}
        </div>
      ))}
      {FIELDS.map((field, i) => (
        <React.Fragment key={field.key}>
          <div className={`${getTmtClassNames(i % 2 === 0)} heading`}>{field.text}</div>
          {tmt.map((tooth) => {
            const value = getValue(movementData[tooth][field.key], step === caseStages.malocStage, isEnrolledPhase2);
            return (
              <div className={getTmtClassNames(i % 2 === 0, missing_teeth.includes(tooth), isToothActive(tooth), value)} key={tooth}>
                <input
                  placeholder={missing_teeth.includes(tooth) ? '' : '-'}
                  disabled={missing_teeth.includes(tooth) || viewOnly}
                  value={value}
                  onFocus={() => onFocus(tooth)}
                  onChange={(e) => onChange(e, tooth, field.key)}
                  onBlur={(e) => onBlur(e, tooth, field.key)}
                  onKeyDown={onKeyDown}
                />
                <HideContentIf condition={!isEnrolledPhase2}>
                  <span className="tmt-input-sign">{getSign(movementData[tooth][field.key], field.signs, step === caseStages.malocStage)}</span>
                </HideContentIf>
              </div>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
}

WasmMovementTable.propTypes = {
  caseStages: PropTypes.exact({
    malocStage: PropTypes.number,
    idealStage: PropTypes.number,
    overStage: PropTypes.number,
  }),
};

const mapStateToProps = (state) => {
  return {
    movement_table: getWasmViewerState(state).movement_table,
    missing_teeth: getWasmViewerState(state).missing_teeth,
    active_teeth: getWasmViewerState(state).active_teeth,
    selectedToothIds: getActiveToothIds(state),
    step: getWasmViewerState(state).step,
    isIFSPlanActive: isIFSPlanActiveSelector(state),
    isEnrolledPhase2: isEnrolledProviderEditPhase2(state),
    caseStages: getCaseStages(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMovementTable: updateMovementTable,
      setActiveTeeth: setActiveTeeth,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WasmMovementTable);
