import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { onChangeStep, onToggleOption, saveDraft, updateIprState } from '../../redux/actions/wasm_viewer';
import { isIFSPlanActiveSelector, getWasmViewerState } from '../../redux/selectors/wasm_viewer';
import { wasmRedo, wasmUndo } from './wasm_controller/wasm_controller';
import { WASM_IPR_MODE } from './wasm-constants';
import { getCaseStages } from '../../redux/reducers/common/common_case_details';

function WasmShortcuts(props) {
  const { setActiveStage, onToggleOption, saveDraft, updateIprState, isIFSPlanActive, isRevising, caseStages } = props;

  /**
   *  Workaround to re-render the component when isIFSPlanActive changes
   *  useCallback and useMemo are not working for this case
   */
  const propsThatTriggersRerender = { isIFSPlanActive, isRevising };
  const componentKeyForReRender = JSON.stringify(propsThatTriggersRerender);

  const keyMap = {
    SAVE_DRAFT: 'Control+s',

    UNDO: 'Control+z',
    REDO: 'Control+y',

    INITIAL: ',',
    FINAL: '.',

    TOGGLE_IPR: 'i',
    TOGGLE_GUM: 'g',
    TOGGLE_SUPER: 's',
    TOGGLE_TMT: 't',
    TOGGLE_ANIMATION: 'a',
  };

  const saveDraftHandler = (e) => {
    e.preventDefault();
    if (isRevising) {
      saveDraft();
    }
  };

  const initialHandler = () => {
    setActiveStage(caseStages.malocStage);
  };

  const finalHandler = () => {
    setActiveStage(isIFSPlanActive ? caseStages.overStage : caseStages.idealStage);
  };

  const handlers = {
    SAVE_DRAFT: saveDraftHandler,

    UNDO: wasmUndo,
    REDO: wasmRedo,

    INITIAL: initialHandler,
    FINAL: finalHandler,

    TOGGLE_IPR: () => updateIprState(WASM_IPR_MODE.ipr),
    TOGGLE_GUM: () => onToggleOption('gingiva'),
    TOGGLE_SUPER: () => onToggleOption('superimposition'),
    TOGGLE_TMT: () => onToggleOption('tmt'),
    TOGGLE_ANIMATION: () => onToggleOption('animation'),
  };

  return <GlobalHotKeys key={componentKeyForReRender} keyMap={keyMap} handlers={handlers} />;
}

WasmShortcuts.propTypes = {
  caseStages: PropTypes.exact({
    malocStage: PropTypes.number,
    idealStage: PropTypes.number,
    overStage: PropTypes.number,
  }),
  setActiveStage: PropTypes.func.isRequired,
  onToggleOption: PropTypes.func.isRequired,
  saveDraft: PropTypes.func.isRequired,
  updateIprState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isRevising: getWasmViewerState(state).is_revising,
    isIFSPlanActive: isIFSPlanActiveSelector(state),
    caseStages: getCaseStages(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onToggleOption: onToggleOption,
      saveDraft: saveDraft,
      updateIprState: updateIprState,
      setActiveStage: onChangeStep,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WasmShortcuts);
