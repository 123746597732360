export const WASM_BG = '#383C3F';

export const IPR_HEIGHT = 225;
export const TMT_HEIGHT = 240;

export const WASM_CANVAS_RESIZE_INTERVAL_MS = 50;

export const WASM_ANIMATION_PROGRESS_INTERVAL_MS = 16;
export const WASM_ANIMATION_TOGGLE_OFF_TIMEOUT_MS = 2000;

export const AUTO_SAVE_DRAFT_TIMEOUT = 5000;

export const VIEW = {
  Top: 'Top',
  Bottom: 'Bottom',
  Left: 'Left',
  Right: 'Right',
  Front: 'Front',
  Back: 'Back',
};
export const HORIZONTAL_VIEWS = [VIEW.Left, VIEW.Front, VIEW.Back, VIEW.Right];

export const ARCH = {
  Lower: 'lower',
  LowerT: 'lowerT',
  Both: 'both',
  UpperT: 'upperT',
  Upper: 'upper',
};

export const WASM_IPR_MODE = {
  initial: 'initial',
  ipr: 'ipr',
  maintainContacts: 'maintainContacts',
  autoAdjust: 'autoAdjust',
};

export const WASM_EDITING_MODE = {
  AutoAdjustIPR: 'Auto Adjust IPR',
  SelectTeeth: 'Select Teeth',
};

export const WASM_EDITING_MODE_ID = {
  AutoAdjustIPR: 'auto-adjust-ipr',
  SelectTeeth: 'select-teeth',
};

export const WASM_DEFAULT_EDITING_MODE = WASM_EDITING_MODE.SelectTeeth;
export const WASM_DEFAULT_EDITING_MODE_ID = WASM_EDITING_MODE_ID.SelectTeeth;

export const EditingModeToId = {
  [WASM_EDITING_MODE.AutoAdjustIPR]: WASM_EDITING_MODE_ID.AutoAdjustIPR,
  [WASM_EDITING_MODE.SelectTeeth]: WASM_EDITING_MODE_ID.SelectTeeth,
};
export const IdToEditingMode = {
  [WASM_EDITING_MODE_ID.AutoAdjustIPR]: WASM_EDITING_MODE.AutoAdjustIPR,
  [WASM_EDITING_MODE_ID.SelectTeeth]: WASM_EDITING_MODE.SelectTeeth,
};

export const ETreatmentPlanStage = {
  Malocclusion: 0,
  Ideal: 1,
  Overcorrection: 2,
  DeMal: 1,
  DeIdeal: 2,
  DeOver: 3,
};

export const EBoltonExcessType = {
  Maxillary: 'Maxillary',
  Mandibular: 'Mandibular',
};
