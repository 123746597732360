import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { computed } from '@preact/signals-react';

import ControlBtn from './wasm_control_btn';
import * as Icons from '../icons';
import {
  onToggleOption,
  updateIprState,
  manualSaveCaseDraft,
  onChangeStep,
  setSidebarActiveButton,
  expandSidebar,
  collapseSidebar,
  toggleMandibleShift,
  setMandibleShiftIgnored,
  toggleBracketVisualization,
} from '../../../redux/actions/wasm_viewer';
import { getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import { wasmUndo, wasmRedo, resetIP } from '../wasm_controller/wasm_controller';
import { doesHaveMandibleShift } from '../wasm_helper';
import { WASM_IPR_MODE } from '../wasm-constants';
import { fetchCaseDetailsSilently } from '../../../redux/actions/common/common_case_details';
import { convertAliasToCaseId } from '../../../common/case/case_id';
import { isIFSPlanActiveSelector, hasIPR } from '../../../redux/selectors/wasm_viewer/wasm-viewer';
import { autoSaveSignal, EAutosaveState, lastSavedAtSignal } from '../signals/autosave';
import { SaveIcon } from '../icons/save-icon';
import { sidebarButton, defaultSidebarActiveButton } from '../sidebar/sidebar_constants';
import {
  getCaseStages,
  getPrimeCaseIdAndEslId,
  isEnrolledProviderEditPhase2,
} from '../../../redux/reducers/common/common_case_details';

const saveBtnTooltip = computed(() => {
  const stateToTooltip = {
    [EAutosaveState.Idle]: 'Save Draft (CTRL + S)',
    [EAutosaveState.Failure]: autoSaveSignal.value.error,
    [EAutosaveState.Success]: `Last Saved - ${lastSavedAtSignal.value}`,
  };

  return stateToTooltip[autoSaveSignal.value.state];
});

const saveBtnTitle = computed(() => {
  const stateToTitle = {
    [EAutosaveState.Idle]: 'Save Draft',
    [EAutosaveState.Loading]: 'Saving...',
    [EAutosaveState.Failure]: 'Save Failed',
    [EAutosaveState.Success]: 'Draft Saved',
  };

  return stateToTitle[autoSaveSignal.value.state];
});

/**
 * Displays panel top toolbar
 * @component
 */
function WasmToolBar(props) {
  const {
    is_revising: isRevising,
    onClickReset,
    redo,
    undo,
    step: activeStage,
    gingiva,
    grid,
    tmtDiff,
    iprState,
    superimposition,
    occlusion,
    tmt,
    iprDiff,
    animation,
    setActiveStage,
    onToggleOption,
    revisionNote,
    updateIprState,
    isIFSPlanActive,
    visibilityProcessor,
    sidebar,
    expandSidebar,
    setSidebarActiveButton,
    mandibleShift,
    toggleMandibleShift,
    hasIPR,
    isEnrolledPhase2,
    caseStages,
    onClickBoltonButton,
    toggleBracketVisualization,
  } = props;
  const { ipr, maintainContacts, autoAdjust } = iprState;
  const { areBracketsVisible } = visibilityProcessor;

  const isMalocclusionStage = activeStage === caseStages.malocStage;
  const isIdealStage = activeStage === caseStages.idealStage;
  const isOverStage = activeStage === caseStages.overStage;
  const isDisabled = !isRevising;
  const areIPRAndTMTEmpty = isEmpty(tmtDiff) && isEmpty(iprDiff);
  const isResetButtonDisabled = isRevising ? areIPRAndTMTEmpty : true;
  const isUndoButtonDisabled = !undo || isDisabled || isMalocclusionStage;
  const isRedoButtonDisabled = !redo || isDisabled || isMalocclusionStage;

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  const saveButtons = [
    {
      text: saveBtnTitle.value,
      tooltip: isDisabled ? '' : saveBtnTooltip.value,
      disabled: isDisabled,
      icon: <SaveIcon state={autoSaveSignal.value.state} disabled={isDisabled} />,
      onClick: onSaveClick,
    },
  ];

  const resetButtons = [
    {
      text: 'Reset',
      tooltip: isResetButtonDisabled ? '' : 'Reset',
      icon: <Icons.ResetIcon />,
      disabled: isResetButtonDisabled,
      onClick: onClickReset,
    },
  ];

  const undoRedoButtons = [
    {
      text: 'Undo',
      tooltip: isUndoButtonDisabled ? '' : 'Undo (CTRL + Z)',
      icon: <Icons.UndoIcon />,
      disabled: isUndoButtonDisabled,
      onClick: wasmUndo,
    },
    { text: 'Redo', tooltip: isRedoButtonDisabled ? '' : 'Redo (CTRL + Y)', icon: <Icons.RedoIcon />, disabled: isRedoButtonDisabled, onClick: wasmRedo },
  ];

  const isInitialButtonActive = isIFSPlanActive ? false : isMalocclusionStage;
  const isFinalButtonActive = isIFSPlanActive ? false : isIdealStage || isOverStage;

  const initialFinalButtons = [
    {
      text: 'Initial',
      tooltip: 'Initial (<)',
      icon: <Icons.InitialIcon />,
      active: isInitialButtonActive,
      onClick: () => {
        const nextStage = caseStages.malocStage;
        setActiveStage(nextStage);
        setMandibleShiftIgnored(false);
      },
      disabled: isIFSPlanActive,
    },
    {
      text: 'Final',
      tooltip: 'Final (>)',
      icon: <Icons.FinalIcon />,
      active: isFinalButtonActive,
      onClick: () => {
        const nextOverStage = caseStages.overStage;
        const nextIdealStage = caseStages.idealStage;
        setActiveStage(isIFSPlanActive ? nextOverStage : nextIdealStage);
      },
      disabled: isIFSPlanActive,
    },
  ];

  const animationButtonDisabled = isIFSPlanActive;

  const hasMandibleShift = doesHaveMandibleShift();

  const viewOptionsButtons = [
    { text: 'Gingiva', tooltip: 'Gingiva (G)', icon: <Icons.GingivaIcon />, active: gingiva, onClick: () => onToggleOption('gingiva') },
    {
      text: 'IPR',
      icon: <Icons.IPRIcon />,
      active: ipr,
      onClick: () => updateIprState(WASM_IPR_MODE.ipr),
      dropdown: [
        {
          id: 'maintain-contacts',
          text: 'Maintain Contacts',
          hasToggle: true,
          onClick: () => updateIprState(WASM_IPR_MODE.maintainContacts),
          checked: maintainContacts,
          disabled: isDisabled || isMalocclusionStage,
        },
        {
          id: 'auto-adjust',
          text: 'Auto Adjust IPR',
          hasToggle: true,
          onClick: () => updateIprState(WASM_IPR_MODE.autoAdjust),
          checked: autoAdjust,
          disabled: isDisabled || isMalocclusionStage,
        },
      ],
      dropdownIsHidden: isDisabled || isMalocclusionStage,
    },
    { text: 'Grid', tooltip: 'Grid', icon: <Icons.GridIcon />, active: grid, onClick: () => onToggleOption('grid') },
    {
      text: 'Super',
      tooltip: 'Superimposition (S)',
      icon: <Icons.SuperImpositionIcon />,
      active: superimposition,
      onClick: () => onToggleOption('superimposition'),
    },
    { text: 'Occlu', tooltip: 'Occlusogram', icon: <Icons.OcclusogramIcon />, active: occlusion, onClick: () => onToggleOption('occlusion') },
    {
      text: 'TMT',
      tooltip: 'Teeth Movement Table (T)',
      icon: <Icons.MovementsTableIcon />,
      active: tmt,
      onClick: () => onToggleOption('tmt'),
    },
    {
      text: 'Animation',
      tooltip: animationButtonDisabled ? '' : 'Animation (A)',
      icon: <Icons.AnimationIcon />,
      active: animation,
      disabled: animationButtonDisabled,
      onClick: () => {
        if (activeStage !== caseStages.idealStage) {
          setActiveStage(caseStages.idealStage); // set stage to ideal because animation is currently being stuck when initial is active
        }
        onToggleOption('animation');
      },
    },
  ];

  if (isEnrolledPhase2) {
    viewOptionsButtons.splice(6, 0, {
      text: 'Bite',
      tooltip: 'Bite Visualization',
      icon: <Icons.MandibleShiftIcon />,
      disabled: animation || !hasMandibleShift || isInitialButtonActive,
      active: hasMandibleShift && !mandibleShift.ignored,
      onClick: toggleMandibleShift,
    });
    viewOptionsButtons.splice(7, 0, {
      text: 'Bolton',
      tooltip: 'Bolton Table',
      icon: <i className={'fa fa-bold bolton-icon'} />,
      active: sidebar.collapse === false && sidebar.activeButton === sidebarButton.bolton,
      onClick: () => {
        if (sidebar.activeButton === sidebarButton.bolton) {
          setSidebarActiveButton(defaultSidebarActiveButton);
        } else {
          setSidebarActiveButton(sidebarButton.bolton);
        }
        if (sidebar.collapse) {
          expandSidebar();
        }
        if (typeof onClickBoltonButton === 'function') onClickBoltonButton();
      },
    });
    viewOptionsButtons[1].dropdown.push({
      id: 'remove-ipr',
      text: 'Remove IPR',
      hasToggle: false,
      onClick: resetIP,
      disabled: isDisabled || !hasIPR || isMalocclusionStage || !isRevising,
    });
    viewOptionsButtons.push({
      text: 'Brackets',
      tooltip: 'Brackets Visualization',
      icon: <Icons.BracketsIcon />,
      disabled: animation || isInitialButtonActive || !isIFSPlanActive,
      active: isIFSPlanActive ? areBracketsVisible : false,
      onClick: toggleBracketVisualization,
    });
  }

  /**
   * Retrieves the case id from props
   * @function
   * @Return {String} Processor - The processor of the function
   */
  function getCaseId() {
    const { case_id } = props.case_id;
    return convertAliasToCaseId(case_id);
  }

  /**
   * Retrieves the case id from props
   * @function
   * @Return {String} Processor - The processor of the function
   */
  function getMetadataCaseId() {
    const { caseId } = props.primeCaseIds;
    return convertAliasToCaseId(caseId);
  }

  /**
   * Retrieves the eslingual id from props
   * @function
   * @Return {String} Eslingual id
   */
  function getEslingualId() {
    const { eslingualId } = props.primeCaseIds;
    return eslingualId;
  }

  /**
   * Handles onSave button clicked
   * @function
   */
  function onSaveClick() {
    props.manualSaveCaseDraft(getCaseId(), getMetadataCaseId(), getEslingualId(), revisionNote);
  }

  return (
    <div className="wasm-toolbar">
      <ControlBtn btns={saveButtons} className="w100px" />
      <ControlBtn btns={resetButtons} />
      <ControlBtn btns={undoRedoButtons} />
      <div className="view-controls-btns">
        <ControlBtn btns={initialFinalButtons} />
        <ControlBtn btns={viewOptionsButtons} />
      </div>
    </div>
  );
}

WasmToolBar.propTypes = {
  isIFSPlanActive: PropTypes.bool,
  areBracketsVisible: PropTypes.bool,
  isEnrolledPhase2: PropTypes.bool,
  primeCaseIds: PropTypes.exact({
    caseId: PropTypes.string,
    eslingualId: PropTypes.string,
  }),
  caseStages: PropTypes.exact({
    malocStage: PropTypes.number,
    idealStage: PropTypes.number,
    overStage: PropTypes.number,
  }),
};

const mapStateToProps = (state) => {
  return {
    ...getWasmViewerState(state),
    isIFSPlanActive: isIFSPlanActiveSelector(state),
    isEnrolledPhase2: isEnrolledProviderEditPhase2(state),
    primeCaseIds: getPrimeCaseIdAndEslId(state),
    caseStages: getCaseStages(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onToggleOption: onToggleOption,
      updateIprState: updateIprState,
      fetchCaseDetailsSilently: fetchCaseDetailsSilently,
      manualSaveCaseDraft: manualSaveCaseDraft,
      setActiveStage: onChangeStep,
      setSidebarActiveButton,
      expandSidebar,
      collapseSidebar,
      toggleMandibleShift,
      setMandibleShiftIgnored,
      toggleBracketVisualization,
      hasIPR,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WasmToolBar);
