import React, { useState, useEffect } from 'react';
import { Collapse } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { getActiveToothIds, getMovement, getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import { incMovement, updateFinalMovementTableValues } from '../wasm_controller/wasm_controller';
import { updateMovement } from '../../../redux/actions/wasm_viewer';
import { tmtKeyToDecr, tmtKeyToDeltaType, tmtKeyToIncr } from './wasm_movement_table';
import { HideContentIf } from '../../../common/hocs/hide-content-if';
import * as Icons from '../icons';
import { ECompassControl, EditingModeProcessor } from '../wasm_controller/processors/editing-mode-processor';
import { ETmtKey, EMovementSigns } from './constants';
import { incMovementInputOnChangeValidator, isZero } from './pipes/validator.pipe';
import { takeAbsValue, setSign, incMovementInputOnBlurTransformer } from './pipes/transformer.pipe';
import { getCaseStages, isEnrolledProviderEditPhase2 } from '../../../redux/reducers/common/common_case_details';

const EMovementToControl = {
  [ETmtKey.Occlusal]: ECompassControl.ExtrusionIntrusion,
  [ETmtKey.Vestibular]: ECompassControl.TranslationBuccalLingual,
  [ETmtKey.Mesial]: ECompassControl.TranslationMesialDistal,
  [ETmtKey.Rotation]: ECompassControl.Rotation,
  [ETmtKey.MesialHinge]: ECompassControl.HingeMesial,
  [ETmtKey.DistalHinge]: ECompassControl.HingeDistal,
  [ETmtKey.Angulation]: ECompassControl.Angulation,
  [ETmtKey.RootTorque]: ECompassControl.RootTorque,
  [ETmtKey.CrownTorque]: ECompassControl.CrownTorque,
};

const MOVEMENTS = [
  {
    key: 'Inclination',
    left: 'Inclination',
    right: '',
  },
  {
    key: 'Angulation',
    left: 'Angulation',
    right: '',
  },
  {
    key: 'Rotation',
    left: 'Rotation',
    right: '',
  },
  {
    key: 'Mesial',
    left: 'Distal',
    right: 'Mesial',
  },
  {
    key: 'Vestibular',
    left: 'Lingual',
    right: 'Buccal',
  },
  {
    key: 'Occlusal',
    left: 'Intrusion',
    right: 'Extrusion',
  },
];
const MOVEMENTS_PHASE_2 = [
  {
    key: ETmtKey.Occlusal,
    valueKey: ETmtKey.Occlusal,
    tooltip: 'Intrusion/Extrusion',
    icon: Icons.IntrusionExtrusionIcon,
    signs: EMovementSigns[ETmtKey.Occlusal],
  },
  {
    key: ETmtKey.Vestibular,
    valueKey: ETmtKey.Vestibular,
    tooltip: 'Lingual/Buccal',
    icon: Icons.LingualBuccalIcon,
    signs: EMovementSigns[ETmtKey.Vestibular],
  },
  {
    key: ETmtKey.Mesial,
    valueKey: ETmtKey.Mesial,
    tooltip: 'Distal/Mesial',
    icon: Icons.DistalMesialIcon,
    signs: EMovementSigns[ETmtKey.Mesial],
  },
  {
    key: ETmtKey.Rotation,
    valueKey: ETmtKey.Rotation,
    tooltip: 'Rotation',
    icon: Icons.RotationIcon,
    signs: EMovementSigns[ETmtKey.Rotation],
  },
  {
    key: ETmtKey.MesialHinge,
    valueKey: ETmtKey.MesialHinge,
    tooltip: 'Mesial Hinge Rotation',
    icon: Icons.MesialHingeIcon,
    signs: EMovementSigns[ETmtKey.MesialHinge],
  },
  {
    key: ETmtKey.DistalHinge,
    valueKey: ETmtKey.DistalHinge,
    tooltip: 'Distal Hinge Rotation',
    icon: Icons.DistalHingeIcon,
    signs: EMovementSigns[ETmtKey.DistalHinge],
  },
  {
    key: ETmtKey.Angulation,
    valueKey: ETmtKey.Angulation,
    tooltip: 'Angulation',
    icon: Icons.AngulationIcon,
    signs: EMovementSigns[ETmtKey.Angulation],
  },
  {
    key: ETmtKey.RootTorque,
    valueKey: ETmtKey.Inclination,
    tooltip: 'Root Torque',
    icon: Icons.RootTorqueIcon,
    signs: EMovementSigns[ETmtKey.RootTorque],
  },
  {
    key: ETmtKey.CrownTorque,
    valueKey: ETmtKey.Inclination,
    tooltip: 'Crown Torque',
    icon: Icons.CrownTorqueIcon,
    signs: EMovementSigns[ETmtKey.CrownTorque],
  },
];
/**
 * Get the value of movement based on the step.
 * If the user is seeing the Initial Movement (step = 0), the value should be empty.
 * @param {Number} value - The value of the movement.
 * @param {Boolean} isHidden - The step in the movement process.
 * @param {Boolean} isMultiSelection - Flag indicating whether multiple tooth selected.
 * @param {Boolean} isEnrolledPhase2 - Flag indicating whether the user is in Phase 2.
 * @returns {String} - The adjusted value.
 */
function getValue(value, isHidden, isMultiSelection = false, isEnrolledPhase2 = false) {
  if (!value || isHidden || isMultiSelection) {
    return '';
  }
  const absValue = isEnrolledPhase2 ? takeAbsValue(value) : value;
  return `${absValue}`;
}

/**
 * Returns the sign corresponding to the given value based on the signs object.
 * If the value is falsy, the step is ETreatmentPlanStage.Malocclusion, or isMultiSelection is true,
 * an empty string is returned.
 * @param {number|string} value - The value to determine the sign for.
 * @param {object} signs - The signs object containing the positive and negative signs.
 * @param {boolean} isHidden - The treatment plan stage.
 * @param {boolean} [isMultiSelection=false] - Indicates if the selection is multi-selection.
 * @returns {string} - The sign corresponding to the value.
 */
function getValueSign(value, signs, isHidden, isMultiSelection = false) {
  if (!value || isHidden || isMultiSelection) {
    return '';
  }
  if (typeof value === 'string') {
    const numberValue = Number(value);
    if (numberValue === 0) {
      return '';
    }
  }
  return value < 0 ? signs.neg : signs.pos;
}

function WasmIncMovement(props) {
  const {
    sidebarWidth,
    activeTeethId,
    movementTable,
    isRevising,
    tmtIncrDelta,
    step,
    selectedToothIds,
    isEnrolledPhase2,
    movement: propsMovement,
    caseStages,
    onToggle,
    updateMovement,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [movement, setMovement] = useState(ETmtKey.Occlusal);
  const [movementDataLocalState, setMovementDataLocalState] = useState(movementTable);
  const [editingField, setEditingField] = useState(null);
  const activeTeeth = movementTable?.[activeTeethId];
  const hasActiveTeeth = Boolean(activeTeeth);
  const isMultiSelection = selectedToothIds.length > 1;
  const movementsToShow = isEnrolledPhase2 ? MOVEMENTS_PHASE_2 : MOVEMENTS;
  const style = {
    position: 'absolute',
    top: '10px',
    left: `calc(${sidebarWidth}px + 40px)`,
  };

  /**
   * Handler for decrementing a movement.
   * @param {MouseEvent<HTMLDivElement>} event - The event of clicked element.
   * @param {string} key - The movement key.
   * @param {boolean} isDecrDisabled - Flag indicating if decrementing is disabled.
   */
  const onClickDecr = (event, key, isDecrDisabled) => {
    if (hasActiveTeeth && !isDecrDisabled) {
      const deltaType = tmtKeyToDeltaType[key];
      const delta = tmtIncrDelta[deltaType];
      incMovement({ [key]: -delta });
    }
    if (movement === 'multiplane') {
      event.stopPropagation();
    }
  };
  /**
   * Handler for incrementing a movement.
   * @param {MouseEvent<HTMLDivElement>} event - The event of clicked element.
   * @param {string} key - The movement key.
   * @param {boolean} isIncrDisabled - Flag indicating if incrementing is disabled.
   */
  const onClickInc = (event, key, isIncrDisabled) => {
    if (hasActiveTeeth && !isIncrDisabled) {
      const deltaType = tmtKeyToDeltaType[key];
      const delta = tmtIncrDelta[deltaType];
      incMovement({ [key]: delta });
    }
    if (movement === 'multiplane') {
      event.stopPropagation();
    }
  };

  const collapseClassName = isOpen ? 'wasm-minus' : 'wasm-plus';
  const isDisabled = !isRevising;
  const isInitialStage = step === caseStages.malocStage;
  const onClickTitle = isDisabled ? null : () => setIsOpen(!isOpen);
  const selectMovement = (m) => {
    if (movement === m) {
      setMovement(propsMovement);
    }
    try {
      const control = EMovementToControl[m];
      EditingModeProcessor.handleControlsVisibilityChange(control);
      if (propsMovement !== m) {
        updateMovement(m);
      }
      setMovement(m);
    } catch (e) {
      console.error('Error selecting incremental movement:', e);
    }
  };

  /**
   * Handles the input change event for a specific tooth and field.
   * @param {Object} e - The event object.
   * @param {string} tooth - The tooth identifier.
   * @param {string} field - The field identifier.
   */
  const handleInputChange = (e, tooth, field) => {
    let value = e.target.value;
    const isValid = incMovementInputOnChangeValidator.validate(value);
    if (!isValid) {
      return;
    }

    if (value !== '') {
      const currentValue = movementTable[tooth][field];
      value = setSign(value, { currentValue });
    }

    setMovementDataLocalState((prev) => ({
      ...prev,
      [tooth]: {
        ...prev[tooth],
        [field]: value,
      },
    }));
  };

  /**
   * Saves the value of a field for a specific tooth in the movement table.
   * @param {string} value - The initial value to save.
   * @param {string} tooth - The tooth identifier.
   * @param {string} field - The field to save the value for.
   */
  const saveValue = (value, tooth, field) => {
    let _value = value;
    if (_value !== '') {
      const currentValue = movementTable[tooth][field];
      _value = incMovementInputOnBlurTransformer.transform(_value, { currentValue });
      if (isNaN(_value)) {
        return;
      }
    }
    setMovementDataLocalState((prev) => {
      const newToothData = {
        ...prev[tooth],
        [field]: _value,
      };
      updateFinalMovementTableValues(newToothData);
      return {
        ...prev,
        [tooth]: newToothData,
      };
    });
  };

  /**
   * Handles the blur event of an input field.
   * @param {Event} e - The blur event object.
   * @param {number} tooth - The tooth number.
   * @param {string} field - The field name.
   */
  const handleInputBlur = (e, tooth, field) => {
    saveValue(e.target.value, tooth, field);
    setEditingField((prev) => (prev === field ? null : prev));
  };

  /**
   * Handles the keydown event for the input field.
   * If the Enter key is pressed, it saves the value and updates the editing field.
   * @param {Event} e - The keydown event object.
   * @param {string} tooth - The tooth identifier.
   * @param {string} field - The field identifier.
   */
  const handleKeyDown = (e, tooth, field) => {
    if (e.key === 'Enter') {
      saveValue(e.target.value, tooth, field);
      setEditingField((prev) => (prev === field ? null : prev));
    }
  };

  useEffect(() => {
    if (typeof onToggle === 'function') {
      onToggle(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (movementTable) {
      setMovementDataLocalState(movementTable);
    }
  }, [movementTable]);

  useEffect(() => {
    if (isRevising) {
      setIsOpen(true);
    }
  }, [isRevising]);

  useEffect(() => {
    if (movementTable && !isDisabled && propsMovement) {
      selectMovement(propsMovement);
    }
  }, [propsMovement, isDisabled]);

  if (!movementTable || isDisabled) {
    return null;
  }

  return (
    <div className={cn('wasm-inc-movement', { 'wasm-inc-movement--phase-1': !isEnrolledPhase2, 'wasm-inc-movement--phase-2': isEnrolledPhase2 })} style={style}>
      <div className={cn('wasm-inc-movement-title-bar', 'header', { 'wasm-inc-movement-title-bar--open': isOpen })} onClick={onClickTitle}>
        <div className="wasm-inc-movement-title">Incremental Movement</div>
        <div className="wasm-inc-movement-control">
          <span aria-hidden>
            <div className={collapseClassName} />
          </span>
        </div>
      </div>
      <Collapse in={isOpen}>
        <div
          className={cn('wasm-inc-movement-content', {
            'wasm-inc-movement-content--phase-1': !isEnrolledPhase2,
            'wasm-inc-movement-content--phase-2': isEnrolledPhase2,
          })}
        >
          <div className={cn('wasm-inc-movement-selected-tooth', 'header', { 'wasm-inc-movement-selected-tooth--phase-2': isEnrolledPhase2 })}>
            {hasActiveTeeth ? activeTeethId : '-'}
          </div>
          <div className="horizontal-divider" />
          <div>
            {movementsToShow.map((item) => {
              const decrKey = tmtKeyToDecr[item.key];
              const isDecrDisabled = activeTeeth?.[decrKey] || isInitialStage;
              const incrKey = tmtKeyToIncr[item.key];
              const isIncrDisabled = activeTeeth?.[incrKey] || isInitialStage;
              const leftChevronClassName = cn('wasm-chevron', 'wasm-chevron--left', { disabled: !hasActiveTeeth || isDecrDisabled });
              const rightChevronClassName = cn('wasm-chevron', 'wasm-chevron--right', { disabled: !hasActiveTeeth || isIncrDisabled });
              if (isEnrolledPhase2) {
                return (
                  <div
                    key={item.key}
                    className={cn('wasm-inc-movement-item', 'wasm-inc-movement-item--phase-2', { selected: movement === item.key })}
                    onClick={() => selectMovement(item.key)}
                  >
                    <span data-toggle="tooltip" title={item.tooltip} data-placement="bottom" id={item.key.toLowerCase()}>
                      <item.icon className="wasm-inc-movement-icon" />
                    </span>
                    <span
                      data-toggle="tooltip"
                      title={item.signs.negTooltip}
                      data-placement="bottom"
                      className={leftChevronClassName}
                      aria-hidden
                      onClick={(e) => onClickDecr(e, item.key, isDecrDisabled)}
                    />
                    <span className="input-wrapper">
                      {editingField === item.valueKey && activeTeeth ? (
                        <>
                          <input
                            className={cn({
                              'no-right-padding': isZero(
                                getValue(movementDataLocalState[activeTeeth.AlphanumericID][item.valueKey], isInitialStage, isMultiSelection, true)
                              ),
                            })}
                            value={getValue(movementDataLocalState[activeTeeth.AlphanumericID][item.valueKey], isInitialStage, isMultiSelection, true)}
                            onChange={(e) => handleInputChange(e, activeTeeth.AlphanumericID, item.valueKey)}
                            onBlur={(e) => handleInputBlur(e, activeTeeth.AlphanumericID, item.valueKey)}
                            onKeyDown={(e) => handleKeyDown(e, activeTeeth.AlphanumericID, item.valueKey)}
                            autoFocus
                          />
                          <span className="sign">
                            {getValueSign(movementDataLocalState[activeTeeth.AlphanumericID][item.valueKey], item.signs, isInitialStage, isMultiSelection)}
                          </span>
                        </>
                      ) : (
                        <>
                          <input
                            placeholder="-"
                            className={cn({ 'no-right-padding': !activeTeeth || !movementTable[activeTeeth.AlphanumericID][item.valueKey] })}
                            value={getValue(activeTeeth?.[item.valueKey], isInitialStage, isMultiSelection, true)}
                            onClick={() => setEditingField(item.valueKey)}
                            readOnly
                          />
                          <span className={'sign'}>{getValueSign(activeTeeth?.[item.valueKey], item.signs, isInitialStage, isMultiSelection)}</span>
                        </>
                      )}
                    </span>
                    <span
                      data-toggle="tooltip"
                      title={item.signs.posTooltip}
                      data-placement="bottom"
                      className={rightChevronClassName}
                      aria-hidden
                      onClick={(e) => onClickInc(e, item.key, isIncrDisabled)}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={item.key} className="wasm-inc-movement-item wasm-inc-movement-item--phase-1">
                    <span className="wasm-inc-movement-item--left">{item.left}</span>
                    <span className={leftChevronClassName} aria-hidden onClick={(e) => onClickDecr(e, item.key, isDecrDisabled)} />
                    <input placeholder="-" value={getValue(activeTeeth?.[item.key], isInitialStage, false)} disabled />
                    <span className={rightChevronClassName} aria-hidden onClick={(e) => onClickInc(e, item.key, isIncrDisabled)} />
                    <span className="wasm-inc-movement-item--right">{item.right}</span>
                  </div>
                );
              }
            })}
            <HideContentIf condition={!isEnrolledPhase2}>
              <div
                onClick={() => selectMovement('multiplane')}
                className={cn('wasm-inc-movement-item multi-plane wasm-inc-movement-item--phase-2', { selected: movement === 'multiplane' })}
              >
                <span data-toggle="tooltip" title="Multi-Plane/Single Axis" data-placement="bottom">
                  <Icons.MultiplaneIcon className="wasm-inc-movement-icon" />
                </span>
                <span>Multi-plane</span>
              </div>
            </HideContentIf>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

WasmIncMovement.propTypes = {
  caseStages: PropTypes.exact({
    malocStage: PropTypes.number,
    idealStage: PropTypes.number,
    overStage: PropTypes.number,
  }),
};

const mapStateToProps = (state) => {
  const wasmState = getWasmViewerState(state);
  return {
    activeTeethId: wasmState.active_teeth,
    selectedToothIds: getActiveToothIds(state),
    isEnrolledPhase2: isEnrolledProviderEditPhase2(state),
    movement: getMovement(state),
    movementTable: wasmState.movement_table,
    tmtIncrDelta: wasmState.tmtIncrDelta,
    isRevising: wasmState.is_revising,
    step: wasmState.step,
    sidebarWidth: wasmState.sidebar.width,
    caseStages: getCaseStages(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateMovement: updateMovement,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WasmIncMovement);
