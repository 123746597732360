import Axios from 'axios';

export class ApiService {
  async fetchCaseDetails(caseId) {
    const res = await Axios.get(`/apiV2/casedetails/${caseId}`);
    const normalizedData = res.data;
    normalizedData.casesMap = res.data.cases.reduce((acc, cur) => ({ ...acc, [cur.case_id]: cur }), {});
    return { ...res, data: normalizedData };
  }

  /**
   *
   * @param caseId {String}
   * @param data {{
   *   smile_design_target_date?: String,
   * }}
   * @return {Promise<void>}
   */
  async updateCase(caseId, data) {
    await Axios.put(`/apiv3/case/${caseId}`, data);
  }

  async tpsEnableWasm(case_id) {
    await Axios.post(`/apiv3/tps/enable_wasm/${case_id}`);
  }

  async saveRevisionDraft(caseId, comment) {
    const fdata = new FormData();
    fdata.append('status_comment', comment);
    await Axios.post(`/apiV2/caseaction/${caseId}/save_revision_draft`, fdata);
  }

  async cancelRevisionDraft(caseId) {
    await Axios.post(`/apiV2/caseaction/${caseId}/cancel_revision_draft`);
  }

  async resetRevisionDraft(caseId) {
    await Axios.post(`/apiV2/caseaction/${caseId}/reset_revision_draft`);
  }
}

export const ApiServiceInstance = new ApiService();
