import { saveUploadMetadata } from '../../../components/wasm_viewer/wasm_helper';
import { ApiServiceInstance } from '../../../common/api_service';
import { fetchCaseDetailsSilently } from '../common/common_case_details';
import {
  autoSaveSignal,
  EAutosaveState,
  isAutosaveAvailable,
  isDraftSaveAvailable,
  LAST_SAVED_AT_FORMAT,
  lastSavedAtSignal,
  disableAutosave,
} from '../../../components/wasm_viewer/signals/autosave';
import { createNotification, ENotificationType } from '../../../components/notifications/signals/notifications';
import { getRevisionLastSavedAt } from '../../reducers/common/common_case_details';
import { dateToFormatted } from '../../../common/date';
import {
  getMandibleShiftValues,
  setMandibleShiftIgnored as setWasmMandibleShiftIgnored,
  wasmControllerExecute,
  wasmControllerGetRepresentation,
} from '../../../components/wasm_viewer/wasm_controller/wasm_controller';
import {
  PROCESSOR,
  WASM_ACTION,
  WASM_PROP
} from '../../../components/wasm_viewer/wasm_controller/wasm_controller_const';

export const SET_LOADING = 'SET_LOADING';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const CHANGE_STEP = 'CHANGE_STEP';
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const CHANGE_ARCH = 'CHANGE_ARCH';
export const TOGGLE_OPTION = 'TOGGLE_OPTION';
export const UPDATE_INITIAL_MOVEMENT_TABLE = 'UPDATE_INITIAL_MOVEMENT_TABLE';
export const UPDATE_MOVEMENT = 'UPDATE_MOVEMENT';
export const UPDATE_MOVEMENT_TABLE = 'UPDATE_MOVEMENT_TABLE';
export const UPDATE_MOVEMENT_TABLE_DIFF = 'UPDATE_MOVEMENT_TABLE_DIFF';
export const UPDATE_INITIAL_IPR = 'UPDATE_INITIAL_IPR';
export const UPDATE_IPR_DIFF = 'UPDATE_IPR_DIFF';
export const UPDATE_RSC = 'UPDATE_RSC';
export const UPDATE_IPR_TABLE = 'UPDATE_IPR_TABLE';
export const UPDATE_IPR_STATE = 'UPDATE_IPR_STATE';
export const UPDATE_EDITING_MODE = 'UPDATE_EDITING_MODE';
export const RESET_STATE = 'RESET_STATE';
export const SAVE_DRAFT_START = 'SAVE_DRAFT_START';
export const SAVE_DRAFT_SUCESS = 'SAVE_DRAFT_SUCESS';
export const SAVE_DRAFT_ERROR = 'SAVE_DRAFT_ERROR';
export const SAVE_DRAFT_END = 'SAVE_DRAFT_END';
export const SET_REVISE = 'SET_REVISE';
export const SET_REVISE_ON_CASE_DETAILS_SUCCESS = 'SET_REVISE_ON_CASE_DETAILS_SUCCESS';
export const SET_REVISION_NOTE = 'SET_REVISION_NOTE';
export const SET_REVISION_SUCCESS = 'SET_REVISION_SUCCESS';
export const SET_TREATMENT_PLAN_LIST = 'SET_TREATMENT_PLAN_LIST';
export const SET_ACTIVE_TREATMENT_PLAN = 'SET_ACTIVE_TREATMENT_PLAN';
export const SET_MISSING_TEETH = 'SET_MISSING_TEETH';
export const SET_ACTIVE_TEETH = 'SET_ACTIVE_TEETH';
export const SET_TMT_INCREMENT_DELTA = 'SET_TMT_INCREMENT_DELTA';
export const SET_SELECTED_PLAN_ERROR = 'SET_SELECTED_PLAN_ERROR';
export const SET_IS_RESET_ENABLED = 'SET_IS_RESET_ENABLED';
export const SET_LAST_ACTIVE_TREATMENT_PLAN = 'SET_LAST_ACTIVE_TREATMENT_PLAN';
export const SET_REVISED_TREATMENT_PLAN = 'SET_REVISED_TREATMENT_PLAN';
export const SAVE_REVISION_START = 'SAVE_REVISION_START';
export const SAVE_REVISION_SUCCESS = 'SAVE_REVISION_SUCCESS';
export const SAVE_REVISION_ERROR = 'SAVE_REVISION_ERROR';
export const SAVE_REVISION_END = 'SAVE_REVISION_END';
export const SET_SIDEBAR_ACTIVE_BUTTON = 'SET_SIDEBAR_ACTIVE_BUTTON';
export const SET_SIDEBAR_COLLAPSE = 'SET_SIDEBAR_COLLAPSE';
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH';
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR';
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
export const SET_MANDIBLE_SHIFT_VALUES = 'SET_MANDIBLE_SHIFT_VALUES';
export const SET_VISIBILITY_PROPS = 'SET_VISIBILITY_PROPS';

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    ...loading,
  };
}

export function onUpdateHistory(undo, redo) {
  return {
    type: UPDATE_HISTORY,
    undo: undo,
    redo: redo,
  };
}

export function onChangeView(view) {
  return {
    type: CHANGE_VIEW,
    view: view,
  };
}

export function onChangeArch(arch) {
  return {
    type: CHANGE_ARCH,
    arch: arch,
  };
}

export function onToggleOption(option, value = null) {
  return {
    type: TOGGLE_OPTION,
    option: option,
    value,
  };
}

/**
 * Creates redux action for changing treatment plan stage in WASM
 * @param step {keyof ETreatmentPlanStage}
 * @return {{step, type: string}}
 */
export function onChangeStep(step) {
  return {
    type: CHANGE_STEP,
    step: step,
  };
}

export function updateInitialMovementTable(movement) {
  return {
    type: UPDATE_INITIAL_MOVEMENT_TABLE,
    movement: movement,
  };
}

export function updateMovementTable(new_movement) {
  return {
    type: UPDATE_MOVEMENT_TABLE,
    movement: new_movement,
  };
}

export function updateMovement(movement) {
  return {
    type: UPDATE_MOVEMENT,
    movement: movement,
  };
}

export function updateTMTDiff(diff) {
  return {
    type: UPDATE_MOVEMENT_TABLE_DIFF,
    diff,
  };
}

export function updateInitialIPR(ipr) {
  return {
    type: UPDATE_INITIAL_IPR,
    ipr: ipr,
  };
}

export function updateIprTable(ipr) {
  return {
    type: UPDATE_IPR_TABLE,
    ipr: ipr,
  };
}

export function updateIPRDiff(diff) {
  return {
    type: UPDATE_IPR_DIFF,
    diff,
  };
}

export function updateIprState(iprState) {
  return {
    type: UPDATE_IPR_STATE,
    iprState: iprState,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function saveDraft() {
  return (dispatch) => {
    dispatch(saveDraftStart());

    setTimeout(() => {
      dispatch(saveDraftError());
    }, 2000);
  };
}

export function saveDraftStart() {
  return {
    type: SAVE_DRAFT_START,
  };
}

export function saveDraftSuccess() {
  return {
    type: SAVE_DRAFT_SUCESS,
  };
}

export function saveDraftError(err) {
  return {
    type: SAVE_DRAFT_ERROR,
  };
}

export function saveDraftEnd() {
  return {
    type: SAVE_DRAFT_END,
  };
}

export function saveRevisionStart() {
  return {
    type: SAVE_REVISION_START,
  };
}

export function saveRevisionSuccess() {
  return {
    type: SAVE_REVISION_SUCCESS,
  };
}

export function saveRevisionError(err) {
  return {
    type: SAVE_REVISION_ERROR,
  };
}

export function saveRevisionEnd() {
  return {
    type: SAVE_REVISION_END,
  };
}

export function setReviseState(isRevising) {
  return {
    type: SET_REVISE,
    isRevising: isRevising,
  };
}

export function setReviseOnCaseDetailsSuccess(case_details) {
  return {
    type: SET_REVISE_ON_CASE_DETAILS_SUCCESS,
    case_details,
  };
}

export function setRevisionNote(note) {
  return {
    type: SET_REVISION_NOTE,
    note,
  };
}

export function setRevisionSuccessState(isSuccess) {
  return {
    type: SET_REVISION_SUCCESS,
    isSuccess,
  };
}

export function setTreatmentPlanList(list, index) {
  return {
    type: SET_TREATMENT_PLAN_LIST,
    list: list,
    index: index,
  };
}

export function setActiveTreatmentPlan(active_plan) {
  return {
    type: SET_ACTIVE_TREATMENT_PLAN,
    active_plan: active_plan,
  };
}

export function setMissingTeeth(missing_teeth) {
  return {
    type: SET_MISSING_TEETH,
    missing_teeth: missing_teeth,
  };
}

export function setActiveTeeth(teeth) {
  return {
    type: SET_ACTIVE_TEETH,
    teeth: teeth,
  };
}

/**
 * Creates redux action for setting TMT increment deltas
 * @param delta {{Spacial: Number, Angular: Number}}
 * @return {{payload: {spacial: Number, angular: Number}, type: string}}
 */
export function setTMTIncrementDelta(delta) {
  return {
    type: SET_TMT_INCREMENT_DELTA,
    payload: delta,
  };
}

export function updateEditingMode(editingModeState) {
  return {
    type: UPDATE_EDITING_MODE,
    state: editingModeState,
  };
}

export function setIsResetEnabled(isResetEnabled) {
  return {
    type: SET_IS_RESET_ENABLED,
    isResetEnabled,
  };
}

export function setSelectedPlanError(error) {
  return {
    type: SET_SELECTED_PLAN_ERROR,
    payload: { error },
  };
}

export function setLastActiveTreatmentPlan(lastActiveTreatmentPlan) {
  return {
    type: SET_LAST_ACTIVE_TREATMENT_PLAN,
    payload: { lastActiveTreatmentPlan },
  };
}

export function setRevisedTreatmentPlan(revisedTreatmentPlan) {
  return {
    type: SET_REVISED_TREATMENT_PLAN,
    payload: { revisedTreatmentPlan },
  };
}

/**
 * Sets the active button in the sidebar.
 * @param {string} activeButton - The active button to be set.
 * @returns {object} - The action object with type and payload.
 */
export function setSidebarActiveButton(activeButton) {
  return {
    type: SET_SIDEBAR_ACTIVE_BUTTON,
    payload: { activeButton },
  };
}

/**
 * Sets the collapse state of the sidebar.
 *
 * @param {boolean} collapse - The collapse state of the sidebar.
 * @returns {object} - The action object with the type and payload.
 */
export function setSidebarCollapse(collapse) {
  return {
    type: SET_SIDEBAR_COLLAPSE,
    payload: { collapse },
  };
}

/**
 * Sets the width of the sidebar.
 * @param {number} width - The width of the sidebar.
 * @returns {object} - The action object with type and payload.
 */
export function setSidebarWidth(width) {
  return {
    type: SET_SIDEBAR_WIDTH,
    payload: { width },
  };
}

export function expandSidebar() {
  return {
    type: EXPAND_SIDEBAR,
  };
}

export function collapseSidebar() {
  return {
    type: COLLAPSE_SIDEBAR,
  };
}

export function setMandibleShiftValues(values) {
  return {
    type: SET_MANDIBLE_SHIFT_VALUES,
    payload: values,
  };
}

/**
 * Sets the value of mandible shift ignored flag.
 *
 * @param {boolean} ignored - The value to set for mandible shift ignored flag.
 * @returns {Function} A Redux thunk function.
 */
export function setMandibleShiftIgnored(ignored) {
  return (dispatch) => {
    const { status } = setWasmMandibleShiftIgnored(ignored);
    if (status === 200) {
      return dispatch(setMandibleShiftValues({ ignored }));
    }
  };
}

/**
 * Toggles the mandible shift value.
 * @returns {Function} A Redux thunk function.
 */
export function toggleMandibleShift() {
  return (dispatch) => {
    const { Ignored: ignored } = getMandibleShiftValues();
    const newValue = !ignored;
    const { status } = setWasmMandibleShiftIgnored(newValue);
    if (status === 200) {
      return dispatch(setMandibleShiftValues({ ignored: newValue }));
    }
  };
}

/**
 * Toggles the brackets visualization.
 * @returns {Function} A Redux thunk function.
 */
export function toggleBracketVisualization() {
  return (dispatch, getState) => {
    const { visibilityProcessor } = getState().wasmViewerReducer;
    const nextBracketsVisible = !visibilityProcessor.areBracketsVisible;
    const req = {
      Action: WASM_ACTION.Visibility.Update,
      BracketsVisible: nextBracketsVisible,
      Processor: PROCESSOR.Visibility,
    };
    wasmControllerExecute(req);
  };
}

/**
 * Saves case draft automatically to server
 * @param caseId {String}
 * @param metadataCaseId {String}
 * @param eslingualId {String}
 * @param revisionNote
 * @param [options] {{
 *   isFailingSilently: Boolean,
 * }}
 * @return {(function(*): Promise<*|undefined>)|*}
 */
export function autoSaveCaseDraft(caseId, metadataCaseId, eslingualId, revisionNote, { isFailingSilently } = {}) {
  return async (dispatch) => {
    if (!isAutosaveAvailable.value) {
      return console.warn('Autosave disabled. Try to save manually.');
    }

    autoSaveSignal.value = { state: EAutosaveState.Loading, isLoading: true, error: '' };

    try {
      await Promise.all([
        saveUploadMetadata(
          metadataCaseId,
          eslingualId,
          () => {},
          () => {}
        ),
        ApiServiceInstance.saveRevisionDraft(caseId, revisionNote),
      ]);

      const case_details = await fetchCaseDetailsSilently(caseId)(dispatch);
      const timestamp = getRevisionLastSavedAt({ case_details });
      lastSavedAtSignal.value = dateToFormatted(timestamp, LAST_SAVED_AT_FORMAT);
      autoSaveSignal.value = { state: EAutosaveState.Success, isLoading: false, error: '' };
    } catch (e) {
      console.warn('error auto-saving the draft:', e.toString());

      if (isFailingSilently) {
        return disableAutosave();
      }

      autoSaveSignal.value = { state: EAutosaveState.Failure, isLoading: false, error: 'AutoSave failed. Please save manually.' };
      createNotification('AutoSave failed. Please save manually.', ENotificationType.Error);
    }
  };
}

/**
 * Saves case draft manually to server
 * @param caseId {String}
 * @param metadataCaseId {String}
 * @param eslingualId {String}
 * @param revisionNote
 * @return {(function(*): Promise<*|undefined>)|*}
 */
export function manualSaveCaseDraft(caseId, metadataCaseId, eslingualId, revisionNote) {
  return async (dispatch) => {
    if (!isDraftSaveAvailable.value) {
      return console.warn('Draft saving already in progress. Cancelling current draft saving.');
    }

    dispatch(saveDraftStart());
    autoSaveSignal.value = { state: EAutosaveState.Loading, isLoading: true, error: '' };

    try {
      await Promise.all([
        saveUploadMetadata(
          metadataCaseId,
          eslingualId,
          () => {},
          () => {}
        ),
        ApiServiceInstance.saveRevisionDraft(caseId, revisionNote),
      ]);

      dispatch(saveDraftSuccess());

      const case_details = await fetchCaseDetailsSilently(caseId)(dispatch);
      const timestamp = getRevisionLastSavedAt({ case_details });
      lastSavedAtSignal.value = dateToFormatted(timestamp, LAST_SAVED_AT_FORMAT);
      autoSaveSignal.value = { state: EAutosaveState.Success, isLoading: false, error: '' };
    } catch (e) {
      const errMessage = e.toString();
      console.warn('error manually-saving the draft:', errMessage);
      dispatch(saveDraftError(e.toString()));
      autoSaveSignal.value = { state: EAutosaveState.Failure, isLoading: false, error: 'Save failed. Please try again later.' };
    }
  };
}

export function setVisibilityProps(props) {
  return {
    type: SET_VISIBILITY_PROPS,
    payload: props,
  };
}
