import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

import './styles.scss';
import { changeWasmStep } from '../../wasm_controller/wasm_controller';
import { getActiveStage, getWasmViewerState } from '../../../../redux/selectors/wasm_viewer';
import { getCaseStages } from '../../../../redux/reducers/common/common_case_details';

const WasmViewInitialStageDump = ({ activeStage, isWasmLoading, isWasmError, isAnimating, isInitialStage, tmt, grid, caseStages }) => {
  const handleMouseEnter = () => changeWasmStep(caseStages.malocStage);
  const handleMouseLeave = () => changeWasmStep(activeStage);

  if (isWasmLoading || isWasmError || isAnimating || isInitialStage) {
    return null;
  }

  const className = cn('wasm-view-initial-stage', {
    'with-tmt': tmt,
    'with-grid': grid,
  });

  return <div className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>View Initial</div>;
};

WasmViewInitialStageDump.propTypes = {
  activeStage: PropTypes.number,
  isWasmLoading: PropTypes.bool,
  isWasmError: PropTypes.bool,
  isAnimating: PropTypes.bool,
  isInitialStage: PropTypes.bool,
  caseStages: PropTypes.exact({
    malocStage: PropTypes.number,
    idealStage: PropTypes.number,
    overStage: PropTypes.number,
  }),
};

const mapStateToProps = (state) => {
  const wasmState = getWasmViewerState(state);
  const caseStages = getCaseStages(state);
  return {
    activeStage: getActiveStage(state),
    isWasmLoading: wasmState.loading,
    isWasmError: !!wasmState.error || !!wasmState.selectedTreatmentPlanError,
    isAnimating: wasmState.animation,
    isInitialStage: getActiveStage(state) === caseStages.malocStage,
    tmt: wasmState.tmt,
    grid: wasmState.grid,
    caseStages,
  };
};

const mapDispatchToProps = () => ({});

export const WasmViewInitialStage = connect(mapStateToProps, mapDispatchToProps)(WasmViewInitialStageDump);
